.Bottom_Nav {
  background: #222533 none repeat scroll 0 0;
  border: 0 none;
  width: 100%;
  display: flex;
  align-items: center;
}

.SideDrawerButton{
  margin-left: 10px;
  margin-right: 10px;
  
}

.Logo_Img {
  padding-top: 20px;
  padding-bottom: 10px;
  margin-left: 15px !important;
}

.Nav_Links {
  margin-top: 30px !important;
  margin-right: 40px !important;
}

.Items {
  color: white !important;
}

.Services {
  color: white !important;
}

.Burger_Button {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
