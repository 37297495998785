.Overlay {
  background: linear-gradient(0deg, #00000080, #00000080),
    url(../images/contact.jpg);

  width: 100%;
  height: 100%;
  position: relative;
  left: 0;
  margin-bottom: 10px;
}

.Content {
  margin-top: 10px;
}

.Content > h3 {
  padding-top: 10px;
  color: #fff;
  font-family: "futura_ltbook", sans-serif;
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 60px;
  text-transform: inherit;
}
