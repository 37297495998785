.Nav_Links {
  
   margin-top: 40px !important;
  }
.Items {
    color: white !important;
  }
  
  .Services {
    color: white !important;
  }