.Overlay {
  background: #00000080 none repeat scroll 0 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0;
}

.Content {
  margin-top: 200px;
}

.Content h1 {
  color: #fff;
  margin-bottom: 20px;
}
.Content h1.second_heading {
  color: #ffcb0f;
  margin-bottom: 35px;
}
.Content > h3 {
  color: #fff;
  font-family: "futura_ltbook", sans-serif;
  font-size: 40px;
  font-weight: normal;
  margin-bottom: 60px;
  text-transform: inherit;
}
.Content > p {
  font-size: 23px;
  line-height: 28px;
}
