.Side_Drawer{
    height: 100%;
    background: #222533 none repeat scroll 0 0;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 60%;
    max-width: 400px;
    z-index: 200;
    transform:translateX(-100%);
    transition :transform 0.3s ease-out;
}

.Side_Drawer.open{
    transform: translateX(0);
}