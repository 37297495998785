@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
}
html{
  height: 100%;
}
body{
  height: 100%;
}