.OurPartners {
    background: rgba(0, 0, 0, 0) url("../images/portfolio_bg.jpg") no-repeat scroll 0 0;
    background-size:cover;
    position:relative;
    padding-bottom: 102px;
    border-bottom:2px solid #ffcb0f;
  }
  .OurPartners::before {
    position:absolute;
    content:"";
    height:100%;
    width:100%;
    background: rgba(84, 84, 82, 0.85);
    top:0;
    left:0;
 
  }
  
  