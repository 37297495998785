.Overlay {
  background: linear-gradient(0deg, #00000080, #00000080),
    url(../images/about.jpg);
  /*  background: #00000080 none repeat scroll 0 0; */
  width: 100%;
  height: 100%;
  position: relative;

  left: 0;
}

.Content {
  margin-top: 10px;
}

.Content > h3 {
  color: #fff;
  font-family: "futura_ltbook", sans-serif;
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 46px;
  padding-top: 10px;
  text-transform: inherit;
}
.Content > p {
  font-size: 16px;
  line-height: 28px;
  padding-bottom: 10px;
  color: #fff;
}
