.AboutUs {
  padding-bottom: 50px !important;
  padding-top: 50px !important;
}

.AboutUsBg {
  position: relative;
  top: 30;
  left: 0;
}
.ManImage {
  margin-left: 55px;
  height: 390px;
  width: auto;
}

.FirstHeader {
  font-size: 40px !important;
  font-weight: bolder !important;
}

h3 {
  font-size: 30px !important;
  font-weight: bolder !important;
}
