.Footer {
  background: url(../images/footer_bg.jpg) no-repeat scroll 0 0;
  background-size: cover;
  position: relative;
  color: rgb(255, 255, 255);
}
.Footer::before {
  background: rgba(255, 255, 255, 0.33) none repeat scroll 0 0;
  content: "";
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.Footer::after {
  background: rgba(34, 37, 51, 0.9) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.Segment {
  color: white;
  z-index: 1;
  position: relative;
}
