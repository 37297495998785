.ContactForm {
  background: #fedb5d none repeat scroll 0 0;
  padding: 27px 0 32px;
}

h2 {
  color: #73757e !important;
}

.First_Heading {
  font-size: 30px !important;
}

.Second_Heading {
  font-size: 40px !important;
  font-family: "futura_ltbook", sans-serif !important;
  margin: 8px 0 !important;
}
