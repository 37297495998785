.Container {
  background: #f2f7fa none repeat scroll 0 0;
  padding-bottom: 70px;
  padding-top: 50px;
  line-height: 18px;
}
.CircleItemsRow {
  margin-top: 50px;
}
.CircleItem {
  border-radius: 50%;
  box-shadow: 0 0 0 10px #ffcb0f, 0 0 0 20px #fadb64, 0 0 0 30px #f7e59c;
  width: 15vw;
  height: 15vw;
  text-align: center;
  vertical-align: middle;
  font-size: 55px;
  line-height: 15vw;
}

.Header {
  margin-top: 35px !important;
}

@media screen and (max-width: 1100px) {
  .CircleItem {
    font-size: 30px;
  }
}

@media screen and (max-width: 765px) {
  .CircleItemsRow {
    margin-top: 50px !important;
  }
  .CircleItem {
    margin: auto !important;
  }
  .Header {
    margin-bottom: 50px !important;
  }
}

@media screen and (max-width: 800px) {
  .CircleItem {
    font-size: 30px;
  }
}

@media screen and (max-width: 650px) {
  .CircleItem {
    font-size: 20px;
  }
}
