.Overlay {
  background: linear-gradient(0deg, #00000080, #00000080),
    url(../images/services.jpg);
  /* background-image: url("../images/services.jpg");
  background: #00000080 none repeat scroll 0 0; */
  width: 100%;
  height: 100%;
  position: relative;

  left: 0;
}

.Content {
  /* margin-top: 30px; */
}

.Content h1 {
  color: #fff;
  margin-bottom: 20px;
}
.Content h1.second_heading {
  color: #ffcb0f;
  margin-bottom: 35px;
}
.Content > h3 {
  color: #fff;
  font-family: "futura_ltbook", sans-serif;
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 46px;
  text-transform: inherit;
}
.Content > p {
  font-size: 17px;
  line-height: 28px;
  color: #fff;
}

.ServicesGrid {
  position: relative;
  margin-top: 30px !important;
  margin-bottom: 25px !important;
}
